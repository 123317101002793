import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/workspace/inbox-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "payment-reasons"
    }}>{`Payment Reasons`}</h1>
    <p>{`Payment reason (PR) codes can be synced in from external integrations and associated with applied invoice payments for display to patients.`}</p>
    <h2 {...{
      "id": "model"
    }}>{`Model`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`PaymentReasons`}</inlineCode>{`:`}<ul parentName="li">
          <li parentName="ul">{`Belong to an `}<inlineCode parentName="li">{`Enterprise`}</inlineCode></li>
          <li parentName="ul">{`Have many `}<inlineCode parentName="li">{`InvoicePayments`}</inlineCode>{` through a `}<inlineCode parentName="li">{`PaymentReasonsInvoicePayments`}</inlineCode>{` join table`}</li>
          <li parentName="ul">{`Attributes:`}<ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`code`}</inlineCode>{` (string)`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`description`}</inlineCode>{` (string)`}</li>
            </ul></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "creation"
    }}>{`Creation`}</h2>
    <p>{`There are 2 ways to create Payment Reasons:`}</p>
    <ol>
      <li parentName="ol">{`Syncing in via external integration`}</li>
      <li parentName="ol">{`Creation via API endpoint (POST to `}<inlineCode parentName="li">{`/api/partner/v2/payment_reasons`}</inlineCode>{`)`}</li>
    </ol>
    <h2 {...{
      "id": "custom-descriptions"
    }}>{`Custom descriptions`}</h2>
    <p>{`The Inbox Health patient app provides custom descriptions for PR codes for more user friendly display. These custom descriptions are displayed through the following process:`}</p>
    <ul>
      <li parentName="ul">{`Prerequisite: An `}<inlineCode parentName="li">{`Invoice`}</inlineCode>{` has an `}<inlineCode parentName="li">{`InvoicePayment`}</inlineCode>{` applied to it. That `}<inlineCode parentName="li">{`InvoicePayment`}</inlineCode>{` has a `}<inlineCode parentName="li">{`PaymentReason`}</inlineCode>{` applied to it via the creation of a `}<inlineCode parentName="li">{`PaymentReasonsInvoicePayment`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`In the patient app, the patient clicks to view an invoice, opening the Invoice modal which displays the Payment Reasons if there are any (circled in red).
`}<img alt="Click to view an invoice" src={require("../../static/images/payment-reasons/click-to-view-invoice.png")} />
        <img alt="Invoice modal" src={require("../../static/images/payment-reasons/invoice-modal.png")} /></li>
      <li parentName="ul">{`The description shown (e.g. "$20.00 was billed to you because of your copayment amount") is calculated (`}<inlineCode parentName="li">{`app/models/payment-reason.js#prettyDescription`}</inlineCode>{`) from the following custom descriptions file: (`}<inlineCode parentName="li">{`en-us.yaml`}</inlineCode>{` in inbox-dashboard)`}<ul parentName="li">
          <li parentName="ul">{`Note: If a payment reason's code is not found in this file, it falls back to the original `}<inlineCode parentName="li">{`description`}</inlineCode>{` attribute it was created with.`}</li>
        </ul><pre parentName="li"><code parentName="pre" {...{}}>{`  PR:
     message: "- Patient responsibility amount."
  CO:
     message: "- Insurance adjustment amount."
  CR:
     message: "- Correction or reversal"
  OA:
     message: "- Other adjustment"
  PI:
     message: "- Payer initiated reductions"
  CO42:
     message: "of the total cost was reduced because of our contracted rate with your insurance company."
  CO45:
     message: "of the total cost was reduced because of our contracted rate with your insurance company."
  CO96:
     message: "- This service was not covered by your insurance company. Contact your insurance company for more information."
  PR1:
    message: "was billed to you because of a remaining deductible amount on your plan that applied for this service."
  PR2:
    message: "was billed to you because of co-insurance on your plan that applied for this service."
  PR3:
    message: "was billed to you because of your copayment amount."
  PR22:
    message: "This care should be covered by another payer/insurance company. Verify that you provided complete information for your insurance benefits, workers' compensation, or any other coverage."
  PR227:
    message: "Your insurance company requested additional information from you, but they did not receive it. Contact your insurance company for more information."
  PR96:
    message: "This service was not covered by your insurance company. Contact your insurance company for more information."
  PR27:
    message: "Your insurance coverage was not in effect when these services were received."
`}</code></pre></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      